import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "barrigona-gallery-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <body>
    <div className="gallery">
        <img src="/files/barrigona/20230217_160515.jpg" alt="Iguana"></img>
        <img src="/files/barrigona/20230217_161225.jpg" alt="Covered carport"></img>
        <img src="/files/barrigona/20230217_171743.jpg" alt="Interior"></img>
        <img src="/files/barrigona/20230217_171837WindAndWave.jpg" alt="Interior"></img>
        <img src="/files/barrigona/20230217_171843.jpg" alt="Interior"></img>
    </div>
    </body>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      